import React from 'react'

const normalizeObject = (obj, fields) => {
  const formatedEntity = {}
  fields.forEach(elem => {
    if (typeof elem === 'string') {
      formatedEntity[elem] = obj[elem]
    } else if (typeof elem === 'object') {
      Object.keys(elem).forEach(key =>
        Object.assign(formatedEntity, normalizeObject(obj[key], elem[key]))
      )
    }
  })
  return formatedEntity
}

const normalizeObjectList = (list, fields, key = 'title') => {
  return list.reduce((acc, elem) => {
    const obj = elem.node || elem
    const formatedEntity = normalizeObject(obj, fields)
    acc[formatedEntity[key]] = formatedEntity
    return acc
  }, {})
}

const getUrlVars = url => {
  const vars = {}
  if (!url) return vars

  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value
  })
  return vars
}

const getImage = (image, title, klass='') => {
  if (!image) return null
  const altText = image.altText ? image.altText : title;
  return (
    <img src={process.env.GATSBY_MEDIA_URL + image.name} alt={altText} className={klass} />
  )
}

export { getUrlVars, normalizeObject, normalizeObjectList, getImage }
