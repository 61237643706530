import React from 'react'

import moment from 'moment-timezone'
import { truncate as _truncate } from 'lodash'
import { v4 } from 'uuid'

const RAW_DATE_FORMAT = 'YYYYMMDD'
const WEEK_DAY_FORMAT = 'dddd'
const EVENT_DATE_FORMAT = 'dddd D MMM YYYY'
const EVENT_HOUR_FORMAT = 'h:mm'
const EVENT_AM_PM = 'a'
const TZ = moment.tz.guess()

const EVENT_TYPES = {
  session: '40-minute presentation',
  session15: '15-minute presentation',
  music: 'Interstitial performance',
  break: 'Fluxible Conference break',
  party: 'Fluxible Conference party',
  workshop: 'Fluxible Workshop',
  meetup: 'Fluxible Meetup',
  sponsors: 'Thank our sponsors',
  concert: 'Fluxible Concert',
}

const formatDate = date => {
  if (!moment(date).isValid()) return ''

  const mDate = moment.tz(date, TZ)

  return (
    <>
      <span key={v4()} className="day_of_week">
        {mDate.format('dddd')}{' '}
      </span>
      <span key={v4()} className="month">
        {mDate.format('D MMM')}{' '}
      </span>
      <span key={v4()} className="year">
        {mDate.format('YYYY')}
      </span>
    </>
  )
}

const formatHours = date => {
  if (!moment(date).isValid()) return ''

  const mDate = moment.tz(date, TZ)

  return (
    <React.Fragment key={v4()}>
      {mDate.format(EVENT_HOUR_FORMAT)}
      <span className="ampm">{mDate.format(EVENT_AM_PM)}</span>
    </React.Fragment>
  )
}

const formatDuration = (startDate, endDate) => {
  const start = formatHours(startDate)
  const end = formatHours(endDate)

  if (start === '') return ''

  let duration = []
  duration.push(start)

  if (end !== '') duration.push('\u2009\u2013\u2009')
  duration.push(end)

  return duration
}

const getEventType = type => {
  return EVENT_TYPES[type]
}

const getEventSummary = (summary, description) => {
  return summary && summary !== ''
    ? summary
    : _truncate(description, { length: 100 })
}

const getMapsLink = location => {
  let url = 'https://www.google.com/maps/search/?api=1&query='

  if (location.address) {
    url += encodeURIComponent(location.name + ', ' + location.address)
  } else if (location.latitude && location.longitude) {
    url += location.latitude + ',' + location.longitude
  } else {
    url = ''
  }

  return url
}

export {
  formatDate,
  formatHours,
  formatDuration,
  getEventType,
  getEventSummary,
  getMapsLink,
  RAW_DATE_FORMAT,
  WEEK_DAY_FORMAT,
  EVENT_DATE_FORMAT,
  EVENT_HOUR_FORMAT,
  TZ,
}
